/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DashboardState {
    total_account: {
        all: number;
        ez: {
            total: number;
            paid: number;
            free: number;
            cd: number;
        };
        supreme: {
            total: number;
            paid: number;
            free: number;
            cd: number;
        };
        entry: number;
        bronze: number;
        silver: number;
        gold: number;
        platinum: number;
    };
    total_account_sales: number;
    total_looping_bonus: number;
    total_pairing_bonus: number;
    total_referral_bonus: number;
    total_account_cashout: number;
    total_account_incentives: number;
    total_product_sales: number;
    total_product_unilevel: number;
    total_product_cashout: number;
    total_available_balance: number;
    total_binary_fund: number;
}

const initialState: DashboardState = {
    total_account: {
        all: 0,
        ez: {
            total: 0,
            paid: 0,
            free: 0,
            cd: 0,
        },
        supreme: {
            total: 0,
            paid: 0,
            free: 0,
            cd: 0,
        },
        entry: 0,
        bronze: 0,
        silver: 0,
        gold: 0,
        platinum: 0,
    },
    total_account_sales: 0,
    total_looping_bonus: 0,
    total_pairing_bonus: 0,
    total_referral_bonus: 0,
    total_account_cashout: 0,
    total_account_incentives: 0,
    total_product_sales: 0,
    total_product_unilevel: 0,
    total_product_cashout: 0,
    total_available_balance: 0,
    total_binary_fund: 0,
};

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        saveDashboard(
            state,
            action: PayloadAction<{
                data: {
                    total_account: {
                        all: number;
                        ez: {
                            total: number;
                            paid: number;
                            free: number;
                            cd: number;
                        };
                        supreme: {
                            total: number;
                            paid: number;
                            free: number;
                            cd: number;
                        };
                        entry: number;
                        bronze: number;
                        silver: number;
                        gold: number;
                        platinum: number;
                    };
                    total_account_sales: number;
                    total_looping_bonus: number;
                    total_pairing_bonus: number;
                    total_referral_bonus: number;
                    total_account_cashout: number;
                    total_account_incentives: number;
                    total_product_sales: number;
                    total_product_unilevel: number;
                    total_product_cashout: number;
                    total_available_balance: number;
                    total_binary_fund: number;
                };
            }>
        ) {
            const {
                payload: { data },
            } = action;
            state.total_account.all = data.total_account.all;
            state.total_account.ez.total = data.total_account.ez.total;
            state.total_account.ez.paid = data.total_account.ez.paid;
            state.total_account.ez.free = data.total_account.ez.free;
            state.total_account.ez.cd = data.total_account.ez.cd;
            state.total_account.supreme.total =
                data.total_account.supreme.total;
            state.total_account.supreme.paid = data.total_account.supreme.paid;
            state.total_account.supreme.free = data.total_account.supreme.free;
            state.total_account.supreme.cd = data.total_account.supreme.cd;
            state.total_account.entry = data.total_account.entry;
            state.total_account.bronze = data.total_account.bronze;
            state.total_account.silver = data.total_account.silver;
            state.total_account.gold = data.total_account.gold;
            state.total_account.platinum = data.total_account.platinum;
            state.total_account_sales = data.total_account_sales;
            state.total_looping_bonus = data.total_looping_bonus;
            state.total_pairing_bonus = data.total_pairing_bonus;
            state.total_referral_bonus = data.total_referral_bonus;
            state.total_account_cashout = data.total_account_cashout;
            state.total_account_incentives = data.total_account_incentives;
            state.total_product_sales = data.total_product_sales;
            state.total_product_unilevel = data.total_product_unilevel;
            state.total_product_cashout = data.total_product_cashout;
            state.total_available_balance = data.total_available_balance;
            state.total_binary_fund = data.total_binary_fund;
        },
    },
});

export const { saveDashboard } = dashboardSlice.actions;
export default dashboardSlice.reducer;
