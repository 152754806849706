/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUserState {
    auth: boolean;
    user: any;
    token: any;
}

const initialState: IUserState = {
    auth: !!localStorage.getItem("ez-key"),
    user: localStorage.getItem("ez-user")
        ? JSON.parse(localStorage.getItem("ez-user") || "")
        : null,
    token: localStorage.getItem("ez-key")
        ? JSON.parse(localStorage.getItem("ez-key") || "")
        : null,
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        saveUser(
            state,
            action: PayloadAction<{
                user: any;
                token: string;
            }>
        ) {
            const {
                payload: { user, token },
            } = action;
            localStorage.setItem("ez-key", JSON.stringify(token));
            localStorage.setItem("ez-user", JSON.stringify(user));
            state.auth = true;
            state.user = user;
            state.token = token;
        },
        logOut(state) {
            localStorage.removeItem("ez-key");
            localStorage.removeItem("ez-user");
            localStorage.removeItem("ez-account");
            state.auth = false;
            state.user = null;
            state.token = null;
        },
    },
});

export const { saveUser, logOut } = userSlice.actions;
export default userSlice.reducer;
