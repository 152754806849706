/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IncentivesState {
    total_account_incentives: number;
    total_incentives_cash: number;
    total_incentives_motor: number;
    total_incentives_travel: number;
    total_incentives_car: {
        level_1: number;
        level_2: number;
        level_3: number;
    };
    total_incentives_condo: {
        level_1: number;
        level_2: number;
        level_3: number;
    };
    incentives_history: any;
}

const initialState: IncentivesState = {
    total_account_incentives: 0,
    total_incentives_cash: 0,
    total_incentives_motor: 0,
    total_incentives_travel: 0,
    total_incentives_car: {
        level_1: 0,
        level_2: 0,
        level_3: 0,
    },
    total_incentives_condo: {
        level_1: 0,
        level_2: 0,
        level_3: 0,
    },
    incentives_history: null,
};

const incentivesSlice = createSlice({
    name: "incentives",
    initialState,
    reducers: {
        saveIncentives(
            state,
            action: PayloadAction<{
                data: {
                    total_account_incentives: number;
                    total_incentives_cash: number;
                    total_incentives_motor: number;
                    total_incentives_travel: number;
                    total_incentives_car: {
                        level_1: number;
                        level_2: number;
                        level_3: number;
                    };
                    total_incentives_condo: {
                        level_1: number;
                        level_2: number;
                        level_3: number;
                    };
                    incentives_history: any;
                };
            }>
        ) {
            const {
                payload: { data },
            } = action;
            state.total_account_incentives = data.total_account_incentives;
            state.total_incentives_cash = data.total_incentives_cash;
            state.total_incentives_motor = data.total_incentives_motor;
            state.total_incentives_travel = data.total_incentives_travel;
            state.total_incentives_car.level_1 =
                data.total_incentives_car.level_1;
            state.total_incentives_car.level_2 =
                data.total_incentives_car.level_2;
            state.total_incentives_car.level_3 =
                data.total_incentives_car.level_3;
            state.total_incentives_condo.level_1 =
                data.total_incentives_condo.level_1;
            state.total_incentives_condo.level_2 =
                data.total_incentives_condo.level_2;
            state.total_incentives_condo.level_3 =
                data.total_incentives_condo.level_3;
            state.incentives_history = data.incentives_history;
        },
    },
});

export const { saveIncentives } = incentivesSlice.actions;
export default incentivesSlice.reducer;
