import { configureStore, combineReducers } from "@reduxjs/toolkit";
import eventReducer from "./slices/event";
import uiReducer from "./slices/ui";
import chatUISlice from "./slices/chat-ui";
import userReducer from "./slices/user";
import dashboardReducer from "./slices/dashboard";
import accountReducer from "./slices/account";
import incentivesReducer from "./slices/incentives";
import cashoutReducer from "./slices/cashout";
import productReducer from "./slices/product";
import pincodeReducer from "./slices/pincode";
import settingsReducer from "./slices/settings";

const rootReducer = combineReducers({
    events: eventReducer,
    ui: uiReducer,
    chatUI: chatUISlice,
    user: userReducer,
    dashboard: dashboardReducer,
    account: accountReducer,
    incentives: incentivesReducer,
    cashout: cashoutReducer,
    products: productReducer,
    pincode: pincodeReducer,
    settings: settingsReducer,
});

export const store = configureStore({
    reducer: rootReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
