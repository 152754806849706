/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SettingsState {
    settings: any;
}

const initialState: SettingsState = {
    settings: [],
};

const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        saveSettings(
            state,
            action: PayloadAction<{
                data: any;
            }>
        ) {
            const {
                payload: { data },
            } = action;
            state.settings = data;
        },
    },
});

export const { saveSettings } = settingsSlice.actions;
export default settingsSlice.reducer;
